import React, { useRef, useEffect, useState } from 'react';
import './App.css';

import Webcam from 'react-webcam';

import * as faceapi from 'face-api.js';
import { ColorRing } from 'react-loader-spinner';

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const [admissionNumber, setAdmissionNumber] = useState('');
  const [imgsrc, setImgsrc] = useState('');
  const [faces, setFaces] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleVideo = async () => {
    if (webcamRef.current && webcamRef.current.video.readyState === 4) {
      const video = webcamRef.current.video;
      const detections = await faceapi.detectAllFaces(
        video,
        new faceapi.TinyFaceDetectorOptions()
      );

      setFaces(detections);

      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      // Ensure canvas and video are the same size
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      ctx.lineWidth = 5;
      ctx.strokeStyle = 'yellow';

      detections.forEach((detection) => {
        const { x, y, width, height } = detection.box;
        ctx.strokeRect(x, y, width, height);
      });
    }
  };

  useEffect(() => {
    const loadModels = async () => {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceExpressionNet.loadFromUri('/models'),
      ]);
    };

    loadModels();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleVideo();
    }, 100);

    return () => clearInterval(interval);
  }, [faces]);

  const capture = async () => {
    setLoader(true);
    if (!admissionNumber) {
      setLoader(false);
      alert('Please enter the admission number.');
      return;
    }

    if (faces) {
      console.log('faces', faces);
      const imageSrc = webcamRef.current.getScreenshot();
      setImgsrc(imageSrc);
      const response = await fetch('https://mainpy.crownelearn.in/store-face', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ faces, admissionNumber, imageSrc }),
      });
      console.log('response', response);
      const data = await response.json();
      if (data.status === 'success') {
        setLoader(false);
        alert(`Student added successfully: ${data.admissionNumber}`);
        setAdmissionNumber('');
      } else {
        setLoader(false);
        alert(data.message);
        setAdmissionNumber('');
      }
    } else {
      setLoader(false);
      alert('Align face before capturing...');
    }
  };

  const takeAttendance = async () => {
    setLoader(true);
    const imageSrc = webcamRef.current.getScreenshot();
    setImgsrc(imageSrc);
    const response = await fetch(
      'https://mainpy.crownelearn.in/take-attendance',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageSrc }),
      }
    );

    const data = await response.json();
    if (data.status === 'success') {
      setLoader(false);
      alert(
        `Attendance recorded for admission number: ${data.admissionNumber}`
      );
    } else {
      setLoader(false);
      alert(data.message);
    }
  };

  const deleteStudent = async () => {
    setLoader(true);
    if (!admissionNumber) {
      setLoader(false);
      alert('Please enter the admission number before deleting.');
      return;
    }

    const response = await fetch(
      'https://mainpy.crownelearn.in/delete-student',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admissionNumber }),
      }
    );

    const data = await response.json();
    if (data.status === 'success') {
      setLoader(false);
      alert(`Student Deleted for admission number: ${data.admissionNumber}`);
      setAdmissionNumber('');
    } else {
      setLoader(false);
      alert(data.message);
      setAdmissionNumber('');
    }
  };

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = '#000000';
    e.target.style.color = '#ffffff';
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = '#ffffff';
    e.target.style.color = '#000000';
  };

  return (
    <div className="App">
      {loader && (
        <div
          style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 10 }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
      )}
      <header className="App-header">
        <input
          type="text"
          placeholder="Enter Admission Number"
          value={admissionNumber}
          onChange={(e) => setAdmissionNumber(e.target.value)}
          style={{ marginBottom: '10px', padding: '10px' }}
        />
        <Webcam
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            zindex: 9,
            width: 640,
            height: 480,
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            zindex: 9,
            width: 640,
            height: 480,
          }}
        />

        <div
          style={{
            position: 'flex',

            padding: '10px',
          }}
        >
          <button
            onClick={capture}
            style={{
              position: 'relative',
              zIndex: 10,
              marginTop: '500px',
              marginRight: '4px',
              padding: '10px',
              cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Capture Face
          </button>
          <button
            onClick={takeAttendance}
            style={{
              position: 'relative',
              zIndex: 10,
              marginTop: '10px',
              padding: '10px',
              cursor: 'pointer',
              marginRight: '4px',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <span
              style={{
                width: '25px',
                height: '20px',
                margin: '2px',
                border: '4px solid #ffffff',
                borderRadius: '60%',
                background: '#000000',
              }}
            ></span> */}
            Take Attendance
          </button>
          <button
            onClick={deleteStudent}
            style={{
              position: 'relative',
              zIndex: 10,
              marginTop: '10px',
              padding: '10px',
              cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Delete Student
          </button>
        </div>
      </header>
    </div>
  );
}

export default App;
